import https from '@/router/https'

//请求生活详情列表
export function getlifeDetails(param) {
  return https.fetchGet('/mallapp/shoplist/shopListParticulars', 
  param);
}
//请求生活详情中猜你喜欢的列表
export function getlifeDetailstyped(param) {
  return https.fetchGet('/mallapp/shoplist/getYouLike', 
  param);
}










