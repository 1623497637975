<template>
  <div>
    <!-- <div>
      <div class="setting-header">
        <p class="setting-two" @click="goNative">
          <van-icon name="arrow-left" size="24px" />
        </p>
        <p class="setting-tress">店铺详情</p>
        <p class="setting-one">
          <img src="../../assets/分享.png" alt />
        </p>
      </div>
    </div> -->
    <!-- 导航 -->
    <van-nav-bar
      title="店铺详情"
      left-arrow
      @click-left="$goBack()"
      :fixed="true"
      placeholder
      @click-right="getliseDetailslist.collectId ? getNoFavoritesr() : getFavoritesr()"
    >
      <template #right>
        <van-icon v-if="getliseDetailslist.collectId" name="star" size="18" color="#FF5200" />
        <van-icon v-else name="star-o" size="18" color="#333" />
      </template>
    </van-nav-bar>
    <div class="setting-center">
      <img
        :src="getliseDetailslist.imgUrl"
        style="width: 100%; height: 200px"
      />
      <!-- <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in getliseDetailslist.imgUrl" :key="index">
          <img style="width: 100%; height: 200px" />
        </van-swipe-item>
      </van-swipe> -->

      <div style="margin-top: 10px">
        <span>{{ getliseDetailslist.name }}</span>
        <!-- <span style="float: right">{{(getliseDetailslist.distance/1000).toFixed(1)}}m</span> -->
        <span style="float: right">{{ distance }}</span>
      </div>

      <div style="margin-top: 10px">
        <van-rate
          v-model="getliseDetailslist.goodsScore1"
          allow-half
          void-icon="star"
          void-color="#eee"
          readonly
        />
        <span style="margin-left: 10px; color: #ff5200"
          >{{ getliseDetailslist.goodsScore }}分</span
        >
      </div>

      <div style="margin-top: 10px;display:flex; align-items:center;">
        <span style="color: #999999">{{ getliseDetailslist.name }}</span> <div  style="flex:1;"></div>
        <div style="color:rgb(255, 82, 0);" @click="$router.push({path:'coupon',query:{shopId:$route.query.Id}})">领券</div>
      </div>

      <van-divider />
      <div class="setting-footer">
        <p class="setting-footer-one">
          <span>营业时间:{{ getliseDetailslist.openingTime }} - {{ getliseDetailslist.closingTime }}</span>
          <span>{{ getliseDetailslist.address }}</span>
        </p>
        <div class="setting-footerpell">
          <p class="setting-footer-two" @click="navigation">
            <span>
              <img src="../../assets/导航@2x.png" alt />
            </span>
            <span>导航</span>
          </p>
          <p class="setting-footer-tress" @click="navigationTEL">
            <span>
              <img
                src="../../static/会员升级_slices/操作-电话_slices/操作-电话@2x.png"
                alt
              />
            </span>
            <span>电话</span>
          </p>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px; padding: 10px 20px; background: #ffffff">
      <div
        class="setting-dooper"
        v-for="(item, index) in getliseDetailslist.listGoodsSpu"
        :key="index"  
      >
        <div class="setting-pellerr" v-if="index < 3">
          <div class="dooper-one">
            <van-image
              width="100"
              height="100"
              :src="JSON.parse(item.picUrls)[0]"
            />
          </div>
          <div class="dooper-two">
            <p class="dooper-two-aaa">{{ item.sname }}</p>
            <!-- <p class="dooper-two-bbb">4小时丨小包</p> -->
            <p class="dooper-two-ccc">
              <span>￥{{ item.salesPrice }}</span>
              <del v-if="item.marketPrice>0?'￥{{ item.marketPrice }}':item.marketPrice = 0?'':''">￥{{ item.marketPrice }}</del>
            </p>
          </div>
        </div>

        <!-- <div class="dooper-tress" v-if="index < 3">
          <p class="foure" @click="buy(item.sname, item.salesPrice,item.skuId,item.spuId)">购买</p>
          <p>
            <span class="foure-one">已售</span>
             <span class="foure-two"> {{ item.saleNum }}</span> 
          </p>
        </div> -->
      </div>

      <div
        style="text-align: center; margin-top: 10px"
        v-if="getliseDetailslist.listGoodsSpu.length > 2"
      >
        <div v-show="show">
          <div
            class="setting-dooper"
            v-for="(item, index) in getliseDetailslist.listGoodsSpu"
            :key="index"
          >
            <div class="setting-pellerr" v-if="index > 2">
              <div class="dooper-one">
                <van-image
                  width="100"
                  height="100"
                  :src="JSON.parse(item.picUrls)[0]"
                />
              </div>
              <div class="dooper-two">
                <p class="dooper-two-aaa">{{ item.sname }}</p>
                <!-- <p class="dooper-two-bbb">4小时丨小包</p> -->
                <p class="dooper-two-ccc">
                  <span>￥{{ item.salesPrice }}</span>
                  <span>￥{{ item.marketPrice }}</span>
                </p>
              </div>
            </div>

            <!-- <div class="dooper-tresser" v-if="index > 2">
              <p class="foure" @click="buy(item.sname, item.salesPrice,item.skuId,item.spuId)">购买</p>
              <p>
               <span class="foure-one">已售</span>
               <span class="foure-two"> {{ item.saleNum }}</span>
              
              </p>
            </div> -->
          </div>
        </div>
        <div v-show="!show" @click="show = true">点击查看更多</div>
        <div v-show="show" @click="show = false">收起</div>
      </div>
    </div>

    <div style="margin-top: 20px; padding: 10px 20px; background: #ffffff">
      <img
        style="width: 100%; height: 30px"
        src="../../assets/img/life-like-icon.png"
        alt
      />
    </div>

    <div style="padding: 5px 8px; background: #f6f6f6; width: calc(100% - 16px);">
      <div
        class="lifeDetails-footer"
        v-for="(item, index) in getlifeDetailstypedlist.records"
        :key="index"
        @click="jumpTo(item)"
      >
        <div class="lifeDetails-footer-one">
          <van-image width="100" height="100" :src="item.imgUrl" />
        </div>
        <div class="lifeDetails-footer-two">
          <p class="lifeDetails-one">
            <span>{{ item.name }}</span>
            <span>{{ item.distance }}</span>
          </p>
          <p class="lifeDetails-two">
            <!-- <span>
              <van-rate
                v-model="item.goodsScore1"
                allow-half
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </span> -->
            <span style="font-size: 10px">{{ item.goodsScore }}分</span>
            <span style="font-size: 10px">￥{{ (Number(item.price)).toFixed(1) }}/人</span>
          </p>
          <p class="lifeDetails-tress">{{ item.name }}</p>
          <p
            class="lifeDetails-foure"
            v-for="(item2, index2) in item.listGoodsSpu"
            :key="index2"
          >
            <span v-if="index2<3" class="lifeDetails-foure-on">
              <span>劵￥{{ item2.salesPrice }}</span>
              <span class="foure-once" >{{ item2.sname }}</span>
              <span>已售{{ item2.saleNum }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";

Vue.use(Lazyload);
import {
  getlifeDetails,
  getlifeDetailstyped,
} from "@/api/lifeDetails/lifeDetails";
import { getFavoritesr, getNoFavoritesr } from "@/api/proprietary/proprietary";
import { mapState } from "vuex";

export default {
  data() {
    return {
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      value: 2.5,
      list: [],
      loading: false,
      finished: false,
      Ilatitude: "", //维度
      Id: "", //店铺ID
      Ilongitude: "", //经度
      // messageHandlers:'',
      // Id:'',
      // toFixed:'',
      current: 1,
      size: 5,
      getliseDetailslist: {
        listGoodsSpu: []
      },
      getlifeDetailstypedlist: [],
      name: "",
      salesPrice: "",
      // session: null,
      show: false,
      locationNav: { longitude: "", latitude: "", name: "" },
      locationNavTEL: "0551-6456987",
      distance: null,
    };
  },
  computed: {
    ...mapState(["session"]),
  },


  mounted() {
    //定义全局session
    if (this.$route.query.session != null) {
      // this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.$route.query.session);
    }
  },
  created() {
     window.sendLocation=this.sendLocation
    this.id = this.$route.query.Id;
    console.log(this.id);
    // this.Ilatitude = this.$route.query.Ilatitude || 0;
    // console.log(this.Ilatitude);
    // this.Ilongitude = this.$route.query.Ilongitude || 0;


    //获取酒店客房列表
  try {

    
     window.webkit.messageHandlers.sendLocation.postMessage("sendLocation");
  } catch (error) {
    window.android.sendLocation()
  }



    // this.getlifeDetailstype();
    // this.getlifeDetailspell();
  },
  methods: {

     sendLocation(long,lat){ //获取定位信息的返回函数
          
          // IOS 的返回的是一个数据:'long=177.0000&lat=000000'
          if(this.isIOS()){
              this.Ilongitude=long.split('&')[0].split('=')[1]
              this.Ilatitude=long.split('&')[1].split('=')[1]
              this.getlifeDetailstype();
              this.getlifeDetailspell();
              return
          }
          // Android 没有获取到定位返回的是一个0
        if(long==='0'){
           this.Ilongitude='0'
              this.Ilatitude='0'
              this.getlifeDetailstype();
              this.getlifeDetailspell();
              return       
           }
           // 安卓获取到数据返回的是两个数据
          this.Ilongitude=long
          this.Ilatitude=lat
           this.getlifeDetailstype();
          this.getlifeDetailspell();
  },

    // 取消收藏
    getNoFavoritesr() {
      let params = [this.getliseDetailslist.collectId];
      getNoFavoritesr(params).then((res) => {
        if (res.data.code === 0) {
          this.$toast('取消收藏成功')
          this.getlifeDetailstype();
          this.getlifeDetailspell();
        }
      });
    },
    //收藏
    getFavoritesr() {
      let params = { type: "2", relationIds: [this.id] };
      getFavoritesr(params).then((res) => {
        if (res.data.code === 0) {
          this.$toast('收藏店铺成功')
          this.getlifeDetailstype();
          this.getlifeDetailspell();
        }
      });
    },
    navigationTEL() {
      window.open(`tel://` + this.phone)
      try{ 

        window.webkit.messageHandlers.tel.postMessage( JSON.stringify(this.phone))
         
       }catch{ 
    
         window.android.callPhone(this.phone);
      }

    },
    navigation() {
      this.locationNav.latitude = this.latitude;
      this.locationNav.longitude = this.longitude;
      this.locationNav.name = this.name;
   
     
       try{ 

        window.webkit.messageHandlers.location.postMessage( JSON.stringify(this.locationNav) );
         
       }catch{ 
    
          window.android.openNavigation(this.longitude, this.latitude, this.name);
      }
     
    },
    getlifeDetailstype() {
      let params = {
        id: this.id,
        Ilatitude: this.Ilatitude,
        Ilongitude: this.Ilongitude,
      };
      getlifeDetails(params)
        .then((res) => {
          console.log("99", res);
          this.getliseDetailslist = res.data.data;
          this.phone = res.data.data.phone;
          this.name = res.data.data.name;
          this.longitude = res.data.data.longitude;
          this.latitude = res.data.data.latitude;

          this.getliseDetailslist.goodsScore = (+this.getliseDetailslist
            .goodsScore).toFixed(1);
          this.getliseDetailslist.goodsScore1 =
            +this.getliseDetailslist.goodsScore;

          let distance = +res.data.data.distance;
          if (distance >= 1000) {
            this.distance = (distance / 1000).toFixed(1) + "km";
          } else {
            this.distance = distance.toFixed(1) + "m";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getlifeDetailspell() {
      let params = {
        id: this.id,
        Ilongitude: this.Ilongitude,
        Ilatitude: this.Ilatitude,
        current: this.current,
        size: this.size,
      };
      getlifeDetailstyped(params).then((res) => {
        console.log("19", res);
        if(res.data.data) {
          this.getlifeDetailstypedlist = res.data.data;
          this.getlifeDetailstypedlist.records.map((x) => {
            x.goodsScore = (+x.goodsScore).toFixed(1);
            x.goodsScore1 = +x.goodsScore;
          });
        }
        
          // console.log(this.getlifeDetailstypedlist);
          // this.skuId = res.data.data.records[0].listGoodsSpu[0].skuId;
          // this.spuId = res.data.data.records[0].listGoodsSpu[0].spuId;
        console.log(this.skuId);
        console.log("1", res);
      });
    },
    buy(name, salesPrice,skuId,spuId) {
      this.$router.push({
        path: "placeOrder",
        query: {
          id: this.id,
          name,
          salesPrice,
          skuId,
          spuId,
          type: '5',
          shopId:this.$route.query.Id

        },
      });
    },
    goNative() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 3; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length <= 3) {
          this.finished = true;
        }
      }, 1000);
    },
    jumpTo(item) {
      console.log("1", item);
      this.id = item.id;
      this.getlifeDetailstype();
      this.getlifeDetailspell();

      // 返回顶部
      let ispeed = Math.floor(-this.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        this.scrollTop + ispeed;
    },
  },
};
</script>

<style lang="less" scoped>
.setting-header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  background-color: #fff;
  z-index: 999;
  .setting-one {
    display: flex;
    padding: 10px 10px 10px 0;
    font-size: 17px;
  }
  .setting-two {
    padding: 10px 0 0 10px;
  }
  .setting-tress {
    flex-grow: 1;
    text-align: center;
    line-height: 44px;
    font-size: 17px;
  }
}
.setting-center {
  top: 44px;
  left: 0;
  right: 0;
  // margin-top: 54px;
  background: #ffffff;
  padding: 0 16px 0 16px;
  .setting-footer {
    display: flex;
    justify-content: space-between;
    .setting-footer-one {
      display: flex;
      flex-direction: column;
      flex: 1;
      :first-child {
        font-size: 12px;
        color: rgba(51, 51, 51, 1);
      }
      :last-child {
        font-size: 12px;
        flex-wrap: wrap;
        width: 100%;
        color: rgba(51, 51, 51, 1);
      }
    }
    .setting-footerpell {
      display: flex;
      height: 56px;
      // width: 100%;
      justify-content: space-between;
    .setting-footer-two {
      display: flex;
      flex-direction: column;
      // margin-left: 60px;
     :first-child{
       height: 24px;
     }
      :last-child {
        font-size: 12px;
      }
    }
    .setting-footer-tress {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      :first-child{
        height: 24px;
      }
      :last-child {
        font-size: 12px;
      }
    }
  }
  }
}
.setting-dooper {
  display: flex;
  justify-content: space-between;
  .setting-pellerr {
    display: flex;
    // border: 1px solid red;
    justify-content: space-between;

    .dooper-two {
      display: flex;
      padding-left: 10px;
      flex-direction: column;
      justify-content: space-around;
      .dooper-two-aaa {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
      }
      .dooper-two-bbb {
        font-size: 12px;
        color: rgba(153, 153, 153, 1);
      }
      .dooper-two-ccc {
        :first-child {
          font-size: 18px;
          color: rgba(168, 0, 0, 1);
        }
        :last-child {
          color: rgba(153, 153, 153, 1);
          font-size: 12px;
        }
      }
    }
  }
  .dooper-tress {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // width: 25%;
    .foure {
      font-size: 11px;
      color: #fff;
      text-align: center;
      // line-height: 22px;
      width: 60px;
      margin-top:18px;
      //  align-items: center;
     padding-top: 2px;
      // line-height: 17px;
      flex: 1;
      background: url("../../assets/矩形备份.png") no-repeat;
    }
   :last-child{
     margin-bottom: 12px;
      .foure-one{
        color: #999999;
      }
      .foure-two{
        color: #999999;
      }
    }
  }

  .dooper-tresser {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // width: 15%;
    .foure {
      font-size: 11px;
      color: #fff;
      align-items: center;
      width: 60px;
      flex: 1;
      background: url("../../assets/矩形备份.png") no-repeat;
    }
  :last-child{
    margin-bottom: 12px;
      .foure-one{
        color: #999999;
      }
      .foure-two{
        color: #999999;
      }
    }
  }
}
.lifeDetails-footer {
  width: 100%;
  background-color: #ffff;
  height: 170px;
  // border: 1px solid red;
  border-radius: 5px;
  display: flex;
  .lifeDetails-footer-one {
    // border: 1px solid red;
    // width: 111px;
    width: 105px;
    height: 170px;
    padding: 8px 0 0 5px;
  }
  .lifeDetails-footer-two {
    // width: 244px;
    // border: 1px solid red;
    flex: 1;
    padding: 5px 0 0 5px;
    // border: 1px solid red;
    // height: 100%;
    .lifeDetails-one {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      :first-child {
        color: #000;
        font-weight: bold;
      }
      :last-child {
        color: #999999;
        font-size: 12px;
        padding-right: 15px;
      }
    }
    .lifeDetails-two {
      display: flex;
      //  justify-content: space-between;
      :first-child{
        margin-right: 15px;
      }
    }
    .lifeDetails-tress {
      color: #999999;
      font-size: 12px;
    }
    .lifeDetails-foure {
      padding-top: 5px;
      font-size: 12px;
      // justify-content: space-around;
      .lifeDetails-foure-on {
        // display: block;
        display: flex;
        font-size: 10px;
        
        // justify-content: space-between;
        :first-child {
          //  display: block;
          // margin-top: 10px;
          // width: 200px;
          // font-size: 10px;
          text-align: center;
          padding: 2px 7px 3px 7px;
          color: #fff;
          background: url("../../static/会员升级_slices/形状结合.png") no-repeat;
          background-size: 100%;
          margin-right: 5px;
        }
        .foure-once{
          display: block;
          // border: 1px solid red;
           width: 40%;   
          overflow:hidden; 
          white-space: nowrap; 
          text-overflow:ellipsis
          
        }
        :last-child {
          color: #666;
          
        }
      }
    }
  }
}
</style>